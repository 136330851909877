/*         about page styles        */

.about-page-cover {
  background-color: black;
  border: 2px solid black;
  color: white;
}

.about-page {
  width: 95%;
  max-width: 2000px;
  margin: 6rem auto;
}

.about-page div {
  width: 100%;
}

.about-page div h2 {
  font-size: 3.8rem;
  color: white;
  margin-bottom: 1rem;
  font-weight: 800;
}

.about-page div p {
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 400;
  max-width: 750px;
}

@media (max-width: 767px) {
  .about-page div h2 {
    font-size: 3rem;
  }
}

@media (max-width: 645px) {
  .about-page div h2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 600px) {
  .about-page-cover {
    margin-bottom: 6rem;
  }
}

@media (max-width: 500px) {
  .about-page div h2 {
    font-size: 2.2rem;
  }
}

@media (max-width: 430px) {
  .about-page div h2 {
    font-size: 2rem;
  }
}

/*             Footer styles                 */

footer {
  background-color: black;
  color: white;
  height: 90vh;
  display: flex;
  align-items: center;
}

.footer-container {
  width: 90%;
  margin: 0 auto;
}

.footer-container h1 {
  text-align: center;
  margin-bottom: 5.5rem;
  font-size: 3.2rem;
}

.footer-container h1 span {
  font-family: "Gilroy-Regular";
  display: block;
}

.footer-container h1 a {
  text-decoration: none;
  color: white;
  font-weight: 900;
  font-family: "Gilroy-Medium";
  position: relative;
}

.footer-container h1 a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 6%;
  bottom: 4px;
  left: 0px;
  opacity: 0.8;
  background-color: red;
  transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
}

.footer-container h1 a:hover::before {
  height: 20%;
}

.footer-cont {
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.footer-logo img {
  width: 12rem;
}

.footer-location p {
  font-size: 1.2rem;
}

.social-media-links a {
  width: 100%;
  display: flex;
  align-items: flex-end;
  text-decoration: none;
  margin: 0 0 1rem 0;
}

.social-media-links a p {
  color: white;
  text-decoration: none;
  margin-left: 5px;
}

.social-media-links a:not(:last-child) {
  margin-right: 1.5rem;
}

.social-media-links {
  display: inline-block;
}

.mg-2 {
  margin-bottom: 1.3rem;
}

.mg-3 > p {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

/*        home page styles        */

.home-page {
  height: 87vh;
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

#hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black */
  z-index: 0;
}

.home-page .hero-content {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 30%;
  left: 30%;
  transform: translate(-30%, -30%);
  position: relative;
  z-index: 1;
  color: white; /* Ensures text is visible over the video */
}

.home-page .hero-content img {
  width: 6.5rem;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.home-page h1 {
  font-size: 4.5rem;
  font-weight: 900;
  margin-bottom: 1rem;
}

.home-page h1::selection {
  color: white;
  /* max-width: 1100px; */
}

.home-page div p {
  text-align: center;
  font-size: 1.4rem;
}

.home-page div p:not(:last-child) {
  margin-bottom: 0.5rem;
}

@media (max-width: 1150px) {
  .home-page h1 {
    font-size: 4rem;
  }
}

@media (max-width: 942px) {
  .home-page h1 {
    font-size: 3.5rem;
  }
}

@media (max-width: 826px) {
  .home-page h1 {
    font-size: 3.1rem;
  }
}

@media (max-width: 735px) {
  .home-page h1 {
    font-size: 2.7rem;
  }
}

@media (max-width: 641px) {
  .home-page h1 {
    font-size: 2.4rem;
  }
}

@media (max-width: 573px) {
  .home-page h1 {
    font-size: 2.1rem;
  }

  .home-page img {
    width: 4rem;
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 505px) {
  .home-page h1 {
    font-size: 1.8rem;
  }
}

@media (max-width: 435px) {
  .home-page h1 {
    font-size: 1.4rem;
  }
}

@media (min-width: 2000px) {
  /*      home page styles 2000px     */
  .home-page div img {
    width: 7rem;
  }

  .home-page div h1 {
    font-size: 5.5rem;
    margin-bottom: 1rem;
  }

  .home-page div p {
    font-size: 1.6rem;
  }
}

@media (min-width: 3000px) {
  /*      home page styles 3000px     */
  .home-page div img {
    width: 7.5rem;
  }

  .home-page div h1 {
    font-size: 6.2rem;
    margin-bottom: 1.2rem;
  }

  .home-page div p {
    font-size: 1.9rem;
  }
}
/* for paragraph */

@media (max-width: 670px) {
  .home-page div p {
    font-size: 1.2rem;
  }
}

@media (max-width: 578px) {
  .home-page div p {
    font-size: 1rem;
  }
}

@media (max-width: 485px) {
  .home-page div p {
    font-size: 0.8rem;
  }
}

/*             Media styles here            */

@media (max-width: 767px) {
  nav {
    width: 100%;
  }

  .logodark {
    padding-left: 1rem;
  }

  .navlinks {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #ffffff;
    top: 13vh;
    height: 87vh;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
  }

  .navlinks ul li {
    font-size: 1.8rem;
    display: block;
  }

  .navlinks ul li:not(:last-child) {
    margin: 0 0 4rem 0;
  }

  .navlinks.open {
    visibility: visible;
    opacity: 1;
  }

  .burger {
    display: block;
  }

  .line.open {
    transform-origin: 0% 0%;
    transform: rotate(45deg);
    background-color: #000000;
  }

  .line.open:nth-child(2) {
    opacity: 0;
  }

  .line.open:nth-child(3) {
    transform-origin: 0% 100%;
    transform: rotate(-45deg);
    background-color: #000000;
  }

  video {
    height: 50vh;
  }

  .our-team-text h3 {
    font-size: 2.4rem;
  }

  .clients-page {
    grid-template-columns: 1fr 1fr;
    text-align: center;
  }

  .our-team-grid {
    grid-template-columns: 1fr;
  }

  footer {
    height: 150vh;
  }

  .footer-container h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 768px) and (max-width: 860px) {
  .our-team-text h3 {
    font-size: 3.5rem;
  }
}

@media (max-width: 390px) {
  .home-page h1 {
    line-height: 4.4rem;
  }
}

@media (max-width: 900px) {
  footer {
    height: 150vh;
  }
}

@media (max-width: 560px) {
  footer {
    height: 100vh;
  }
}

@media (max-width: 510px) {
  .footer-container h1 {
    text-align: center;
    margin-bottom: 5.5rem;
    font-size: 2.5rem;
  }
}

@media (min-width: 768px) and(max-width:950px) {
  footer {
    height: 100vh;
  }
  .footer-cont {
    gap: 5rem;
  }
}

@media (max-width: 950px) {
  .each-services-images img {
    width: 100%;
  }
  .hide-mobile {
    /* display: none; */
  }
}

@media (max-width: 900px) {
  .footer-cont {
    margin: 0 auto;
    flex-direction: column-reverse;
    text-align: center;
  }

  .social-media-links a:not(:last-child) {
    margin-right: 0;
  }

  .footer-logo {
    margin-bottom: 3rem;
  }

  .mg-3 {
    margin-bottom: 3rem;
  }
}

@media (max-width: 1000px) {
  .footer-container h1 span {
    display: block;
  }

  .footer-container h1 a::before {
    left: 0px;
    bottom: 8px;
  }
}

@media (min-width: 2000px) {
  /*      about page styles 2000px     */

  .about-page div h2 {
    font-size: 4rem;
  }

  .about-page div p {
    font-size: 1.2rem;
  }

  /*      our team page styles 2000px     */

  .our-team-text h3 {
    font-size: 4rem;
    font-weight: 900;
  }

  .each-team h4 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: 900;
  }

  .each-team h5 {
    margin-bottom: 3.5rem;
    font-size: 1.1rem;
    font-weight: 400;
  }
}

@media (min-width: 3000px) {
  /*      about page styles 3000px     */

  .about-page div h2 {
    font-size: 4.5rem;
  }

  .about-page div p {
    font-size: 1.25rem;
  }

  /*      our team page styles 3000px     */

  .our-team-text h3 {
    font-size: 4.5rem;
    font-weight: 900;
  }

  .our-team-text p {
    font-size: 1.35rem;
    max-width: 600px;
    line-height: 1.5;
  }

  .each-team h4 {
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
    font-weight: 900;
  }

  .each-team h5 {
    margin-bottom: 3.5rem;
    font-size: 1.3rem;
    font-weight: 400;
  }
}

.showreel {
  margin-bottom: 5rem;
}
/*           clients page styles           */

.clients-page {
  max-width: 1660px;
  width: 90%;
  margin: 0 auto 3rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  text-align: center;
}

.clients-page img {
  max-width: 100%;
  width: 170px;
  height: 85px;
  width: 130px;
  height: 110px;
  object-fit: contain;
  cursor: pointer;
  margin-bottom: 5rem;
  /* filter: gray;
  -webkit-filter: grayscale(1);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out; */
}

.globus img {
  width: 160px;
  height: 140px;
}

.dorman img {
  width: 160px;
  height: 140px;
}

.clients-page img:hover {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
}

@media (min-width: 901px) and (max-width: 1100px) {
  .clients-page {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .clients-page {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
